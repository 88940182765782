import React from "react"
import { css } from "@emotion/core"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default ({ location }) => (
  <Layout location={location}>
    <SEO
      title="About"
      description="このサイトについて。"
      pathname="/about"
      article
    />
    <div
      css={css`
        background-color: var(--post-background-color);
        padding: 50px;
        margin-bottom: 50px;
      `}
    >
      <h1>About</h1>
      <h2>このサイトについて</h2>
      <p>このサイトは折登 樹の更新するブログです。</p>
      <p>このサイトは次のような用途で使う予定です。</p>
      <ul>
        <li>自分の制作物をまとめる</li>
        <li>自分の勉強したことを記事にする</li>
        <li>日記のようなものを書く</li>
      </ul>
      <p>
        定期更新などの目標は一切立てず、書きたいものができたときに適当に書くという方針でサイトを更新していく予定です。
      </p>
      <h2>免責事項</h2>
      <p>
        このサイトの記事の内容は個人的に調べたものであり、その正確性や安全性を保証するものではありません。記事で紹介している情報で被った損害に関しては一切の責任を負いかねます。
      </p>
      <p>
        この免責事項、および、このサイトの記事は予告なしに変更・削除されることがあります。
      </p>
      <p>以上、ご了承ください。</p>
      <h2>折登 樹について</h2>
      <ul>
        <li>
          Twitter:{" "}
          <a href="https://twitter.com/MatchaChoco010">@MatchaChoco010</a>
        </li>
        <li>
          GitHub: <a href="https://github.com/MatchaChoco010">MatchaChoco010</a>
        </li>
      </ul>
      <h2>Google Analytics</h2>
      <p>このサイトではGoogle Analyticsを利用しています。</p>
      <p>
        Google
        Analyticsではトラフィックデータの収集のためにCookieを使用しています。
        これは個人を特定するものではありません。
        ブラウザのCookieを無効にすることで、この収集を拒否できます。
      </p>
      <p>
        詳しくは
        <a href="https://policies.google.com/technologies/partner-sites?hl=ja">
          こちらをクリック
        </a>
        してください。
      </p>
    </div>
  </Layout>
)
